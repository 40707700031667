import { useToast } from "vue-toastification";
import type { Menu } from "~/types";

interface MenusState {
    menus: {
        [key in Menu["code"]]?: Menu | undefined;
    };
    loaded: boolean;
}

export const useMenusStore = defineStore("menus", {
    state(): MenusState {
        return {
            menus: {},
            loaded: false,
        };
    },
    actions: {
        async load() {
            if (this.loaded) {
                return;
            }
            const res = await useFetchWithCredentials<Menu[]>("api/v1/menus", {
                key: "menus",
            });
            this.loaded = true;
            if (res.status.value === "error") {
                useToast().error(res.error.value?.data?.message);
                return;
            }

            if (!res.data.value) {
                return;
            }
            res.data.value.forEach((menu) => {
                this.menus[menu.code] = menu;
            });
        },
    },
});
